#header {
    position: relative;
    top: 0;
    width: 100%;
    background-color: #24252A;
    color: white;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
}

#header .header--content {
    margin: 0 3rem;
}

#header .header--content:first-of-type {
    padding: 80px 20px 0;
}

#header .header--content:last-of-type {
    padding: 0 20px 80px;
}

#header .header--content__buttons {
    display: flex;
    align-items: center;
}

#header h1 {
    margin-bottom: 2rem;
}

#header a {
    margin-right: 1rem;
    z-index: 2;
}

#header .header--content__intro p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

#header .button-primary {
    background: transparent;
    border: 2px solid #C3B1E1;
    color: #C3B1E1;
    border-radius: 25px;
    padding: 10px 20px;
    margin-right: 1rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.1rem;
}

#header .button-primary:hover {
    scale: 1.2;
    margin-right: 2rem;
}

#header svg {
    width: 40px;
    height: 40px;
}

@media(min-width: 1200px) {
    #header {
        width: 45%;
        height: 100%;

        position: fixed;
        left: 0;
        top: 0;

        transition: width 1s cubic-bezier(.23, 1, .32, 1) .5s;
    }

    #header .header--content {
        padding: 80px 20px 0;
    }
}

@media(max-width: 576px) {
    #header .header--content:first-of-type {
        padding: 40px 10px 0;
    }

    #header .header--content:last-of-type {
        padding: 0 10px 40px;
    }

    #header .header--content__buttons {
        flex-direction: column;
        align-items: start;
    }

    #header .header--content__buttons--social {
        margin-top: 1rem;
    }
}
