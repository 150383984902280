@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

a {
    color: #C3B1E1;
    text-decoration: none;
}

.section {
    position: sticky;
    top: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section .content {
    margin: 3rem;
    line-height: 2rem;
}

.section p {
    margin-bottom: 1rem;
    text-align: justify;
}

.main {
    background: white;
    width: 55%;
    margin-left: 45%;
    min-height: 100vh;
}

#about-me, #skills {
    background: white;
}

#education, #references {
    background: #C3B1E1;
}

#me img {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: saturate(0) brightness(1.1);
    mix-blend-mode: multiply;
    -o-object-fit: cover;
    object-fit: cover;
}

/* REFERENCES */
.list-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 2.5rem;
}

ul {
    list-style: circle;
}

ul li a {
    color: black;
    font-weight: 500;
}

ul li a:hover {
    text-decoration: underline;
}

/* SKILLS */
.carousel-wrapper {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
}

.splide__slide {
    display: flex;
    justify-content: center;
}

.splide__slide img {
    max-width: 300px;
    height: auto;
}

@media(max-width: 1200px) {
    .main {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 576px) {
    .list-wrapper {
        margin: 0 1rem;
    }

    .splide .splide__arrow--next {
        right: -1rem;
    }

    .splide .splide__arrow--prev {
        left: -1rem;
    }

    #header {
      min-height: 100vh;
    }

    .section {
        position: relative;
        width: unset;
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .section {
        position: relative;
        width: unset;
    }
}

@media (max-width: 450px) {
    .list-wrapper {
        flex-direction: column;
    }
}